import React, { Component } from 'react';

import trinitiLogo from '../img/triniti-logo.svg';

export default class header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      dropdown: false,
      burger: false,
      activeClass: ''
    };

    this.showNav = this.showNav.bind(this);
  }

  showNav(content) {
    let __content = '';
    let dropdown = this.state.dropdown;
    if (this.state.content === content) {
      __content = '';
      dropdown = !dropdown;
    } else {
      __content = content;
      dropdown = true;
    }

    this.setState({
      content: __content,
      dropdown: dropdown
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleScroll() {
    let activeClass;
    if (window.scrollY > 0) {
      activeClass = 'nav-shadow';
    } else {
      activeClass = '';
    }
    this.setState({ activeClass });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <>
        <nav
          className={`navigation-container ${this.state.activeClass} ${
            this.state.width > 990 ? 'desktop-view' : ''
          } ${this.state.dropdown === true ? 'mega-active' : ''} ${
            this.state.burger ? 'mega-active mobile-active' : ''
          }`}
        >
          <div className="mobile-menu">
            <div>
              <a className="main-navigation-item logo" href="/">
                <img
                  className="redirect triniti-logo"
                  alt="Triniti"
                  src={trinitiLogo}
                />
              </a>
            </div>
            <div
              id="burger-container"
              onClick={() => {
                this.setState({ burger: !this.state.burger });
              }}
              className={`${this.state.burger ? 'opened' : ''}`}
            >
              <div
                className={`burger-top ${
                  this.state.burger ? 'burger-top--closed' : ''
                }`}
              ></div>
              <div
                className={`burger-mid ${
                  this.state.burger ? 'burger-mid--closed' : ''
                }`}
              ></div>
              <div
                className={`burger-bot ${
                  this.state.burger ? 'burger-bot--closed' : ''
                }`}
              ></div>
            </div>
          </div>
          <div className="main-navigation mobile-dropdown nav-white">
            <div>
              <a className="main-navigation-item logo" href="/">
                <img
                  alt="Triniti"
                  className="redirect triniti-logo"
                  src={trinitiLogo}
                />
              </a>
            </div>
            <div>
              <a
                onClick={() => this.showNav('products')}
                className={`main-navigation-item with-arrow ${
                  this.state.content === 'products' ? 'active' : 'inactive'
                }`}
              >
                Our Platform
                <i
                  className={`arrow main-nav products ${
                    this.state.content === 'products' ? 'active' : ''
                  }`}
                ></i>
              </a>
              <a
                onClick={() => this.showNav('documentation')}
                className={`main-navigation-item with-arrow ${
                  this.state.content === 'documentation' ? 'active' : 'inactive'
                }`}
              >
                Documentation
                <i
                  className={`arrow main-nav documentation ${
                    this.state.content === 'documentation' ? 'active' : ''
                  }`}
                ></i>
              </a>
              <a className="main-navigation-item redirect" href="/pricing/">
                Pricing
              </a>
             
              <a
                className="main-navigation-item main-navigation-button trial"
                href="https://developer.triniti.ai/register"
                target="_blank"
                rel="noopener noreferrer"
              >
                Free trial
              </a>
              <a
                className="main-navigation-item main-navigation-button login"
                href="https://developer.triniti.ai/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Login
              </a>
            </div>
          </div>

          <div
            className={`mega-menu ${
              this.state.content === 'products' ? 'active' : 'inactive'
            }`}
            data-category="products"
            onClick={()=>{this.setState({content:'', dropdown:false})}}>
            <ul>
              <li>
                <h2 className="mega-menu-title">
                  <i className="arrow mega-menu"></i>Our Platform
                </h2>
                A conversational AI platform to automate customer service &amp;
                engagement
              </li>
            </ul>
            <ul>
              <h2>Products</h2>
              <li>
                <h3>
                  <a className="gotohow" href="/#how-does-it-work">
                    CognitiveQnA
                  </a>
                </h3>
                Setup virtual agents to automate customer service
              </li>
              <li>
                <h3>Cognitive AI (coming soon)</h3>Next gen Conversational AI
                for cross channel conversational services
              </li>
            </ul>
            <ul>
              <h2>Features</h2>
              <li>
                <h3>
                  <a href="/features/#minimal-variants">Minimal training</a>
                </h3>
                Augmented by paraphrasing and synthetic variants
              </li>
              <li>
                <h3>
                  <a href="/features/#high-accuracy">High accuracy</a>
                </h3>
                Deep learning powered algorithms &amp; domain models
              </li>
              <li>
                <h3>
                  <a href="/features/#natural-conversations">
                    Natural conversations
                  </a>
                </h3>
                Human like natural conversations with context and disambiguation
              </li>
              <li>
                <h3>
                  <a href="/features/#omni-channel-capability">
                    OmniChannel capability
                  </a>
                </h3>
                Service customers across channels on voice &amp; text
              </li>
            </ul>
            <ul>
              <h2>Resources</h2>
              <li>
                <h3>
                  <a href="https://docs.triniti.ai">Documentation</a>
                </h3>
                Comprehensive guides on building conversational AI services
              </li>
              <li>
                <h3>
                  <a href="/webinars/">Webinars &amp; videos</a>
                </h3>
                Video tutorials to help you get started
              </li>
            </ul>
          </div>

          <div
            className={`mega-menu ${
              this.state.content === 'documentation' ? 'active' : 'inactive'
            }`}
            data-category="documentation"
            onClick={()=>{this.setState({content:'', dropdown:false})}}>
            <ul>
              <li>
                <h2 className="mega-menu-title">
                  <i className="arrow mega-menu"></i>Documentation
                </h2>
                Flexible pay per use pricing for different needs
              </li>
            </ul>
            <ul>
              <h2>Get Started</h2>
              <li>
                <h3>
                  <a href="https://docs.triniti.ai">Docs home</a>
                </h3>
                Developer guides for building conversational services
              </li>
              <li>
                <h3>
                  <a href="https://docs.triniti.ai/tutorials.html">Tutorials</a>
                </h3>
                Video tutorials to help you get started
              </li>
              <li>
                <h3>
                  <a href="https://docs.triniti.ai/api.html">API reference</a>
                </h3>
                Easy to use REST API's
              </li>
              <li>
                <h3>
                  <a href="/webinars/">Webinars &amp; videos</a>
                </h3>
                Video tutorials to help you get started
              </li>
            </ul>
            <ul>
              <h2>Products</h2>
              <li>
                <h3>
                  <a className="gotohow" href="/#how-does-it-work">
                    CognitiveQnA
                  </a>
                </h3>
                Setup virtual agents to automate customer service
              </li>
              <li>
                <h3>Cognitive AI (coming soon)</h3>Next gen conversational AI
                for cross channel conversational services
              </li>
            </ul>
            <ul>
              <h2>Features</h2>
              <li>
                <h3>
                  <a href="/features/#minimal-variants">Minimal training</a>
                </h3>
                Augmented by paraphrasing and synthetic variants
              </li>
              <li>
                <h3>
                  <a href="/features/#high-accuracy">High accuracy</a>
                </h3>
                Deep learning powered algorithms &amp; domain models
              </li>
              <li>
                <h3>
                  <a href="/features/#natural-conversations">
                    Natural conversations
                  </a>
                </h3>
                Human like natural conversations with context and disambiguation
              </li>
              <li>
                <h3>
                  <a href="/features/#omni-channel-capability">
                    OmniChannel capability
                  </a>
                </h3>
                Service customers across channels on voice &amp; text
              </li>
            </ul>
          </div>
        </nav>
        <div className="navigation-overlay"></div>
      </>
    );
  }
}
