import { Helmet } from 'react-helmet';
import React from 'react';

const HeadTags = props => {
  return (
    <Helmet>
      <html lang="en" />
      <meta charset="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <title>{props.title}</title>

      <meta
        name="description"
        content="Spotter bots handle repetitive customer support queries and supercharge customer support teams to be more efficient."
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={props.content.type} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.content.description} />
      <meta
        property="og:url"
        content={`https://triniti.ai/${props.content.url}`}
      />
      <meta property="og:site_name" content="Triniti" />
      <meta property="og:image" content="../img/Triniti.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:description"
        content="Spotter bots handle repetitive customer support queries and supercharge customer support teams to be more efficient."
      />
      <meta
        name="twitter:title"
        content="Chatbots for Support, Answer Bot | Triniti"
      />
      <meta property="twitter:image" content="../img/Triniti.png" />

      <meta name="generator" content="WordPress 4.9.8" />
      <meta
        name="msapplication-TileImage"
        content="/wp-content/uploads/2018/05/cropped-fav-270x270.png"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Roboto:100,200,300,400,500,600,700|Open+Sans:300,400,600,700"
        rel="stylesheet"
      ></link>
    </Helmet>
  );
};
export default HeadTags;
