import React from 'react';
import { Link } from 'gatsby';

//! Images
import Logo from '../img/logo_bottom.png';
import Twitter from '../img/twitter.png';
import LinkedIn from '../img/linkedin.png';

const footer = props => {
  return (
    <div className="main-footer">
      <div className="container subfooter">
        <div className="row brd-btm">
          <div className="logo col-12 col-md-4 mb-4">
            <img alt="Triniti" src={Logo} />
            <div className="tagline">
              Create natural conversational experiences
            </div>
          </div>
          <div className="col-6 col-md-2 m-h-288">
            <h3>Products</h3>
            <ul id="menu-products" className="main-menu">
              <li
                id="menu-item-1707"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1517 current_page_item menu-item-1707"
              >
                <a href="/">CognitiveQnA</a>
              </li>
              <li
                id="menu-item-1711"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1711"
              >
                <a href="/">
                  Cognitive AI <br /> (coming soon)
                </a>
              </li>
              <li
                id="menu-item-1706"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1706"
              >
                <Link to="/pricing/">Pricing</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-2 m-h-288">
            <ul className="main-menu">
              <h3>Resources</h3>
              <ul id="menu-resources" className="main-menu">
                <li
                  id="menu-item-1712"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1712"
                >
                  <a href="https://docs.triniti.ai/reference.html#triniti-api-sdk">
                    Tutorials
                  </a>
                </li>
                <li
                  id="menu-item-1713"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1713"
                >
                  <a href="https://docs.triniti.ai/api.html">API Reference</a>
                </li>
                <li
                  id="menu-item-1714"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1714"
                >
                  <a href="https://docs.triniti.ai/quickstart.html#quick-start">
                    Quick Start
                  </a>
                </li>
                <li
                  id="menu-item-1715"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1715"
                >
                  <Link to="/webinars/">Webinars</Link>
                </li>
                <li
                  id="menu-item-1716"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1716"
                >
                  <a href="https://docs.triniti.ai/reference.html#triniti-api-sdk">
                    SDK’s
                  </a>
                </li>
              </ul>
            </ul>
          </div>
          <div className="col-6 col-md-2 m-h-288">
            <ul className="main-menu">
              <h3>Support</h3>
              <ul id="menu-support" className="main-menu">
                <li
                  id="menu-item-1717"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1717"
                >
                  <a href="https://discuss.triniti.ai/">Forums</a>
                </li>
                <li
                  id="menu-item-1718"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1718"
                >
                  <a href="https://ask.triniti.ai/">Ask Us</a>
                </li>
              </ul>
            </ul>
          </div>
          <div className="col-6 col-md-2 m-h-288">
            <h3>Company</h3>
            <ul id="menu-company" className="main-menu">
              <li
                id="menu-item-1732"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1732"
              >
                <a href="https://active.ai/about">About</a>
              </li>
              <li
                id="menu-item-1733"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1733"
              >
                <a href="/legal/">Legal</a>
              </li>
              <li
                id="menu-item-1734"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1734"
              >
                <a href="https://active.ai/careers">Careers</a>
              </li>
              <li
                id="menu-item-1735"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1735"
              >
                <a href="https://active.ai/in-the-news/">Media</a>
              </li>
              <li
                id="menu-item-1736"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1736"
              >
                <a href="https://active.ai/#ca_clients">Partners</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container footer-bottom">
        <div className="row pb-5 mb-5 pb-md-0 mb-md-0 footer-align">
          <div className="col-12 col-sm-3 copyright">
            © {new Date().getFullYear()} Active Intelligence Pte Ltd
          </div>
          <ul id="menu-legal" className="col-sm-6 legal">
            <li
              id="menu-item-1740"
              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1740"
            >
              <Link to="/legal/#privacy-policy">Privacy</Link>
            </li>
            <li
              id="menu-item-1738"
              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1738"
            >
              <Link to="/legal/#terms-of-service">Terms of Service</Link>
            </li>
            <li
              id="menu-item-1739"
              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1739"
            >
              <a href="https://triniti.ai/legal/#acceptable-use-policy">
                Acceptable Use Policy
              </a>
            </li>
          </ul>
          <div className="col-sm-3 socialWrapper">
            <a
              href="https://twitter.com/activeaibot"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="Twitter" className="social" src={Twitter} />
            </a>
            <a
              href="https://www.linkedin.com/company/activeaibot/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="LinkedIn" className="social" src={LinkedIn} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default footer;
