import React, { Component } from 'react';

export default class CTA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: true,
      email: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    const isChecked = this.state.checked;

    this.setState({
      checked: !isChecked
    });
  }

  render() {
    return (
      <div id="cta">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2>Ready to supercharge your customer support</h2>
              <p>Try TRINITI for free for 30 days - No credit card required</p>
              <div className="cta-input col-sm-10 col-md-8 col-lg-6 offset-sm-1 offset-md-2 offset-lg-3">
                <div className="input-wrap">
                  <input
                    type="text"
                    name="email_field"
                    id="email_field"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                  <button
                    id="cta_btn"
                    className="btn"
                    onClick={() => {
                      window.location.replace(
                        `https://developer.triniti.ai/register?email=${this.state.email}`
                      );
                    }}
                  >
                    Sign Up
                  </button>
                </div>
                <label className="containerCheck col-md-12 col-lg-10 offset-lg-1">
                  Keep me updated with latest developments in TRINITI
                  <input
                    name="subscribe"
                    onClick={this.handleChange}
                    type="checkbox"
                    defaultChecked={this.state.checked}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
